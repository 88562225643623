<app-menubar></app-menubar>
<div class="home-wrapper">
    <div class="swiper-container">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
            <!-- Slides -->
            <div style="background-image: url('../../../assets/images/slide1.jpg');" class="swiper-slide slide"></div>
            <div style="background-image: url('../../../assets/images/slide2.jpg');" class="swiper-slide"></div>
            <div style="background-image: url('../../../assets/images/slide3.jpg');" class="swiper-slide"></div>
            <div style="background-image: url('../../../assets/images/slide4.jpg');" class="swiper-slide"></div>
            <div style="background-image: url('../../../assets/images/slide5.jpg');" class="swiper-slide"></div>
            ...
        </div>
        <!-- If we need pagination -->
        <!-- <div class="swiper-pagination"></div> -->
    
        <!-- If we need navigation buttons -->
        <!-- <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div> -->
    
        <!-- If we need scrollbar -->
        <!-- <div class="swiper-scrollbar"></div> -->
    </div>
</div>

<div class="hero-title font-white d-flex flex-column justify-content-center align-items-center">
    <h1>
        IMPERATO E ASSOCIATI
    </h1>
    <h5>Studio Legale</h5>
    <!-- <img src="../../../assets/images/logo.png" style="transform: rotate(90deg);" height="300px"> -->
    <!-- <h2>dal 2014</h2> -->
</div>
