import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../aree-competenza/aree-competenza.component';

@Component({
  selector: 'app-dialog-ac',
  templateUrl: './dialog-ac.component.html',
  styleUrls: ['./dialog-ac.component.scss']
})
export class DialogAcComponent implements OnInit {
  testo;
  constructor(
    public dialogRef: MatDialogRef<DialogAcComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  ngOnInit(): void {
    if(this.data.titolo == 'Diritto Penale'){
     
      this.testo = '<p class="paragrafo"><br><br>L\’area di attività dei professionisti verte, in particolare, nell\’ambito dei reati economici, '+
      'dell\’impresa, tributari e fallimentari, ove le molteplici competenze permettono sia '+
      'l\’ausilio consulenziale all\’imprenditore ed all\’impresa nella fisiologia, sia l\’assistenza ' +
      'nella gestione della patologia (intesa come illecito patito od oggetto di indagini '+
      'nell\’esercizio dell\’attività di impresa o nel suo interesse e ciò nei rapporti economici - tra '+
      'privati, verso lo Stato e verso lo Stato Erario -, nella tutela della proprietà intellettuale e '+
      'dalla criminalità informatica, fino all\’assistenza dell\’impresa e dell\’imprenditore nella '+
      'fase prefallimentare e fallimentare).<br>'+
      'Ancora, l\’ambito di attività riguarda anche l\’area della pubblica amministrazione, '+
      'apprestando sia la tutela del cittadino e dell\’impresa nei rapporti con le devianze della '+
      'Pubblica Amministrazione, sia la tutela del funzionario pubblico in relazione ai complessi '+
      'ambiti legislativi e regolamentari che regolano l\’agire del suo ufficio; inoltre, la '+
      
      'particolare sensibilità ai riflessi interdisciplinari della materia, permette di offrire '+
      'un\’assistenza specifica, completa ed attenta ai risvolti disciplinari ed erariali dell\’illecito. '+
      'Particolari e specifiche competenze riguardano poi il settore dell\’igiene e sicurezza sul ' +
      'lavoro che muovono dall\’analisi dei presidi di prevenzione e protezione, nonché di quelli '+
      'di formazione/informazione, per giungere alla difesa dall\’illecito colposo. Questo '+
      'permette una completa assistenza che può iniziare dal momento genetico di progettazione '+
      'del sistema interno di verifica e controllo (predisposizione di deleghe, definizione di '+
      'mansioni ed ambiti specifici, ecc. ecc.), a quello finale della gestione dell\’evento di danno '+
      'e dell\’adeguamento del sistema azienda all\’intercettamento di futuri analoghi rischi. '+
      'Quanto al settore della tutela della sicurezza alimentare, sono state maturate competenze '+
      'sia dal lato del consumatore rimasto vittima di condotte illecite e desideroso di ristoro del '+
      'danno patito, sia dal lato del produttore, potendo offrirsi un\’ampia esperienza nella '+
      'gestione della problematica specifica e nella verifica ed identificazione dei processi '+
      'aziendali devianti e nella loro correzione, nonché rispetto alla normativa di settore, che '+
      'presenta specificità e complessità di rilievo.<br>'+
      'Anche il diritto penale dell’edilizia e ambiente richiede approfondimento e progettualità '+
      'nella difesa, imponendo un necessario rapporto con la normativa di settore e con i '+
      'provvedimenti amministrativi legati alla condotta illecita (ad esempio: ordini di bonifica, '+
      'ordini di sospensione dei lavori e di demolizione) che, necessariamente, vengono adottati '+
      'nei casi concreti. I professionisti dello studio hanno maturato esperienza e professionalità '+
      'nel settore e, pertanto, sono in grado di guidare l\’assistito nelle articolate e determinanti '+
      'scelte che la materia impone.<br>'+
      'Infine, i professionisti dello studio hanno maturato un\’approfondita esperienza in materia '+
      'di diritto penale di famiglia e minorile e stalking, potendo offrire la tutela sia della '+
      'persona offesa sia della persona indagata/imputata, con riferimento alle molteplici '+
      'fattispecie delittuose contro la famiglia, sia la tutela del minore, autore e vittima del reato,'+
      'quale soggetto autonomo di diritti soggettivi nell\’ambito del peculiare processo minorile; '+
      'inoltre, la particolare sensibilità ai riflessi interdisciplinari della materia, permette di '+
      'offrire un\’assistenza specifica, completa ed attenta anche con riferimento ai risvolti '+
      'stragiudiziali relativi alla risoluzione dei conflitti familiari, nonché all\’individuazione dei '+
      'più efficaci strumenti volti al recupero del minore. I professionisti dello studio hanno '+
      'altresì sviluppato una particolare competenza in relazione al reato di Stalking e, più in '+
      'generale ai reati di violenza contro le donne, ottenendo altresì l\’iscrizione al Fondo di '+
      'Solidarietà istituito dalla Regione Piemonte per la tutela legale delle donne vittime di '+
      'violenza.</p>';
    }else if(this.data.titolo=="Diritto Tributario"){
      this.testo="<br><br><span class=\"st\">Attività di verifica fiscale</span><br><br> "+
      "<p class= \"paragrafo\">Lo studio è in grado di assistere il cliente – persona fisica o persona giuridica, residente e "+
      " non residente sul territorio dello Stato – sin dai primi rapporti con l’Amministrazione "+
      " Finanziaria o con la Guardia di Finanza, operando quali procuratori speciali nelle "+
      " operazioni di verifica, così fornendo l’indispensabile apporto professionale durante tutta "+
      " l’attività di verifica fiscale.<br> "+
      " L’assistenza comprende anche l’eventuale fase della definizione precontenziosa "+
      " nell’ambito delle procedure deflattive del contezioso (contraddittori ante accertamento, "+
      " accertamento con adesione, ravvedimento operoso).<ber> "+
      " I professionisti hanno altresì maturato una significativa esperienza quanto agli istituti "+
      " \“perdonistici\” (condoni fiscali, scudo fiscale, voluntary disclosure) e, pertanto, possono "+
      " offrire un’assistenza qualificata anche su questo piano di intervento.<br><br><br></p> "+
      " <span class=\"st\">Contenzioso tributario</span><br><br><p class=\"paragrafo\"> "+
      "I professionisti dello studio si occupano dell’assistenza delle persone fisiche e delle "+
      "persone giuridiche dinnanzi agli organi della Giustizia tributaria in ogni grado del "+
      "giudizio sino al patrocinio presso la Corte di Cassazione. "+
      "Lo studio segue altresì l’eventuale fase della conciliazione giudiziale sino alla definizione "+
      "della controversia nell’ambito del predetto istituto, curando i rapporti con "+
      "l’Amministrazione Finanziaria in ogni suo aspetto procedurale.<br><br><br> "+
      "<span class=\"st\">Procedimento e processo per reati tributari</span><br><br> "+
      "L’esperienza maturata in ambito penale tributario garantisce al Cliente una specifica"+
      "competenza nei rapporti con l’Autorità Giudiziaria rispetto alla notizia di reato, che possa "+
      "scaturire all’esito della verifica fiscale."+
      "I professionisti sono, pertanto, pienamente in grado di gestire sia il versante"+
      " amministrativo, sia il versante penale delle attività di verifica ed accertamento fiscale. "+
      " Nel corso di questi anni, peraltro, i professionisti hanno intrattenuto e continuano ad "+
      " intrattenere rapporti professionali e di collaborazione con dottori commercialisti, esperti "+
      " contabili, consulenti d’impresa. Questi rapporti hanno consentito di sviluppare la capacità "+
      "di collaborare con profitto e soddisfazione con diverse professionalità, nei rispettivi "+
      "ambiti, anche per quanto le interferenza che necessariamente si manifestano fra l’ambito "+
      "fiscale e quello, parallelo, penale.</p>"
    }else if(this.data.titolo=="Ordinamento di prevenzione"){
      this.testo="<p class=\"paragrafo\"><br><br>"+
      "L’ordinamento di prevenzione, pur nato con l’esplicita finalità di lotta alla criminalità "+
      "organizzata attraverso lo stretto controllo dei soggetti ritenuti pericolosi e la confisca del "+
      "denaro e dei beni di provenienza illecita, sta assumendo una crescente importanza per il "+
      "sempre più frequente ricorso alla misura di prevenzione personale e, soprattutto, "+
      "patrimoniale.<br>"+
      "Si tratta di un ordinamento autonomo rispetto a quello penale, seppur allo stesso finitimo "+
      "per molti tratti, e dotato di peculiari caratteri e procedure; proprio nella complessità di "+
      "questo settore si è cercato di sviluppare una professionalità specifica utile alla migliore"+
      " tutela del soggetto proposto, ma, soprattutto, dei terzi interessati colti, nei loro interessi"+
     " patrimoniali, dalla misura di prevenzione."+
      "</p>"
    }else if(this.data.titolo=="Responsabilità amministrativa degli enti"){
      this.testo="<p class=\"paragrafo\"><br><br>"+
      "Da ultimo, lo studio fornisce un’assistenza approfondita ed attenta – anche frutto "+
      "dell’esperienza personale dell’avv. Imperato quale presidente di diversi OdV – "+
      "nell’ambito della responsabilità dell’ente ai sensi del decreto legislativo n. 231 del 2001: "+
      "dallo studio e costruzione del Modello, alla sua attuazione, alla sua revisione ed "+
      "aggiornamento in linea con le continue modifiche normative in materia e, infine, alla "+
      "partecipazione dei professionisti all’OdV.<br>"+
      "Sotto questo profilo, i professionisti possono esercitare la propria specifica competenza "+
      "professionale rispetto ai problemi che, di recente, si sono manifestati nell’ambito della "+
      "responsabilità degli enti, a seguito dell’introduzione dei reati tributari e di contrabbando "+
      "quali reati presupposto di questa forma di responsabilità. <br> "+
      "Lo studio è, inoltre, in grado di fornire una verifica ed un’analisi settoriale, rivolta"+
      "all’evidenza del rischio fiscale dell’attività, sia per quanto concerne i riflessi sulla "+
      "responsabilità dell’ente, sia per quanto concerne la prevenzione del contenzioso "+
      "tributario."+
      "</p>"
    }
  }

  


  
  chiudi(): void {
    this.dialogRef.close();
  }

}
