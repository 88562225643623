import { Component, OnInit } from '@angular/core';
declare let ScrollMagic : any;
@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.scss']
})
export class StudioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    var controller = new ScrollMagic.Controller();

    // create a scene
    new ScrollMagic.Scene({ triggerElement: ".riga1", triggerHook:0.2 })

    .setClassToggle('#riga2', 'animate__fadeIn')
    // .setClassToggle('#bg1', 'colore')
    // .addIndicators() 
    .addTo(controller);
    
  }

}
