<app-menubar></app-menubar>

<div class="header-page margin-menu">
    <div class="container chisiamo ml-auto pl-0 pr-0 pt-5 mt-3 mr-auto">
        <h1>Chi siamo</h1>
        <!-- <p class="mt-5 pt-3 paragrafo">
            Lo studio <span class="font-verde">Imperato e Associati</span> è composto da quattro professionisti: <span
                class="font-verde">Lorenzo Imperato</span>, <span class="font-verde">Raffaella Enrietti</span>, <span
                class="font-verde">Marco Longo</span> e <span class="font-verde">Francesca Peyron</span>.
            <br>
            Insieme hanno lavorato per <span class="font-verde">oltre dieci anni</span> e, dal dicembre <span
                class="font-verde">2014</span>, hanno deciso di continuare a farlo in
            forma associata per valorizzare la loro forte sintonia e la spiccata capacità di <span
                class="font-verde">integrare le rispettive
                competenze</span>.
            <br>
            La peculiare esperienza professionale di ciascuno degli avvocati che compongono lo studio consente
            un’<span class="font-verde">assistenza attenta</span>, <span class="font-verde">qualificata</span> e <span
                class="font-verde">completa</span> sia al privato che all’impresa ed in molti settori del
            diritto, con particolare riferimento al diritto penale, al diritto tributario, alla responsabilità
            amministrativa degli enti e all’ordinamento di prevenzione.
        </p> -->
        <p class="mt-5 pt-3 paragrafo">
            Lo studio Imperato e Associati è composto da quattro professionisti: Lorenzo Imperato,
            Raffaella Enrietti, Marco Longo e Francesca Peyron.
            Insieme hanno lavorato per oltre dieci anni e, dal dicembre 2014, hanno deciso di
            continuare a farlo in forma associata per valorizzare la loro forte sintonia e la spiccata
            capacità di integrare le rispettive competenze. La peculiare esperienza professionale di
            ciascuno degli avvocati che compongono lo studio consente un’assistenza attenta,
            qualificata e completa – sia al privato, sia all’impresa – in molti settori del diritto, con
            particolare riferimento al diritto penale, al diritto tributario, alla responsabilità
            amministrativa degli enti e all’ordinamento di prevenzione, sul versante patrimoniale.
            Nel 2020 lo studio si è trasferito nello storico palazzo di corso Re Umberto 77 ed i soci
            hanno iniziato una collaborazione con lo studio Cianci/Law, fondato dagli avvocati
            Andrea Cianci e Monica Toscani, dando vita ad una struttura integrata, moderna ed
            efficiente che, grazie a coerenti competenze multidisciplinari, si propone quale punto di
            riferimento sia per il diritto penale sia per lo specifico settore del diritto tributario.
        </p>
    </div>

    <div class='icon-scroll'></div>
</div>
<div style="background-image: url('../../../assets/images/imperato-sfondo.jpg');" id="bg1" class="bg1 bg-para">

</div>
<div class="sub-page">
    <a href="../../../assets/curriculum/curriculum-imperato.pdf" target="_blank">
    <div class="container p-2 d-flex desktop flex-row ml-auto mr-auto">
        
        <div class="col-6 d-flex flex-column align-items-center justify-content-center p-3">
            <img id="foto1" class="img-fluid foto1 foto-profilo bianco-nero" height=""
                src="../../../assets/images/lorenzo-imperato.jpg">
        </div>
        <div class="col-6 staff-desc p-3 d-flex flex-column justify-content-center">
            <div>
                <h1>Lorenzo Imperato</h1>
                <h5><i class="font-verde">Avvocato</i></h5>
            </div>
            <!-- <p class="mt-5 paragrafo">
                Nato a Torino il 12 settembre 1966.

                Laureato in giurisprudenza presso la Facoltà di Giurisprudenza dell’Università di Torino il 25 febbraio
                1991, con una tesi di laurea dal titolo: «La disciplina penaltributaria dei costi» (relatore: prof. Ivo
                Caraccioli – votazione 107/110 con menzione)




            </p> -->
            
                <span class="leggi">Curriculum<svg enable-background="new 0 0 512 512" height="512" class="icon-leggi"
                        viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                            xmlns="http://www.w3.org/2000/svg" />
                    </svg>
                </span>
           
        </div>
    
    </div>
</a>
    <div class="container mobile p-2 d-flex riga-r ml-auto mr-auto">
        <a href="../../../assets/curriculum/curriculum-imperato.pdf" target="_blank">
            <div class="d-flex flex-column align-items-center justify-content-center p-3">
                <img id="foto11" class="img-fluid foto1 foto-profilo bianco-nero" height=""
                    src="../../../assets/images/lorenzo-imperato.jpg">
            </div>
            <div class="text-center staff-desc p-3 d-flex flex-column justify-content-center">
                <div>
                    <h1>Lorenzo Imperato</h1>
                    <h5><i class="font-verde">Avvocato</i></h5>
                </div>
                <!-- <p class="mt-5 paragrafo">
                Nato a Torino il 12 settembre 1966.

                Laureato in giurisprudenza presso la Facoltà di Giurisprudenza dell’Università di Torino il 25 febbraio
                1991, con una tesi di laurea dal titolo: «La disciplina penaltributaria dei costi» (relatore: prof. Ivo
                Caraccioli – votazione 107/110 con menzione)




            </p> -->
                <!-- <span class="asc leggi">Curriculum<svg enable-background="new 0 0 512 512"
                    height="512" class="icon-leggi" viewBox="0 0 512 512" width="512"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                        xmlns="http://www.w3.org/2000/svg" />
                </svg>
            </span> -->
            </div>
        </a>
    </div>
</div>

<div style="background-image: url('../../../assets/images/enrietti-sfondo.jpg');" id="bg2" class="bg-para bg2 "></div>
<div class="sub-page">
    <a href="../../../assets/curriculum/CV-Raffaella Enrietti.pdf" target="_blank">
    <div class="container p-2 d-flex desktop flex-row ml-auto mr-auto">
        <div class="col-6 staff-desc text-right p-3 d-flex flex-column justify-content-center">
            <!-- <div class="text-right"> -->
            <div>
                <h1>Raffaella Enrietti</h1>
                <h5><i class="font-verde">Avvocato</i></h5>
            </div>
            <!-- </div> -->
            <!-- <p class="mt-5 paragrafo">
                Nata a Ivrea (TO) il giorno 27 ottobre 1972.

                Dopo il diploma di Maturità si è laureata nel 1997 in Giurisprudenza presso l’Università degli Studi di
                Torino con una tesi in Diritto Penale (prof. A. Rossi Vannini) su «La tutela delle acque», conseguendo
                la votazione di 110/110.


            </p> -->
            
                <span class="leggi justify-content-end">Curriculum<svg enable-background="new 0 0 512 512" height="512"
                        class="icon-leggi" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                            xmlns="http://www.w3.org/2000/svg" />
                    </svg>
                </span>
            
        </div>
        <div class="col-6 d-flex flex-column align-items-center justify-content-center p-3">
            <img id="foto2" class="foto2 img-fluid bianco-nero foto-profilo" height=""
                src="../../../assets/images/raffaella-enrietti.jpg">
        </div>
    </div>

</a>

    <div class="container d-flex mobile riga-r p-2 ml-auto mr-auto">
        <a href="../../../assets/curriculum/CV-Raffaella Enrietti.pdf" target="_blank">
            <div class="d-flex flex-column align-items-center p-3 justify-content-center">
                <img id="foto22" class="foto2 img-fluid bianco-nero foto-profilo" height=""
                    src="../../../assets/images/raffaella-enrietti.jpg">
            </div>
            <div class=" text-center staff-desc text-right p-3 d-flex flex-column justify-content-center">
                <!-- <div class="text-right"> -->

                <div class="">
                    <h1>Raffaella Enrietti</h1>
                    <h5><i class="font-verde">Avvocato</i></h5>
                </div>
                <!-- </div> -->
                <!-- <p class="mt-5 paragrafo">
                Nata a Ivrea (TO) il giorno 27 ottobre 1972.

                Dopo il diploma di Maturità si è laureata nel 1997 in Giurisprudenza presso l’Università degli Studi di
                Torino con una tesi in Diritto Penale (prof. A. Rossi Vannini) su «La tutela delle acque», conseguendo
                la votazione di 110/110.


            </p> -->
                <!-- <span routerLink="/curriculum/enrietti" class="leggi  asc">Curriculum<svg
                    enable-background="new 0 0 512 512" height="512" class="icon-leggi" viewBox="0 0 512 512"
                    width="512" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                        xmlns="http://www.w3.org/2000/svg" />
                </svg>
            </span> -->
            </div>
        </a>

    </div>
</div>

<div style="background-image: url('../../../assets/images/longo-sfondo.jpg');" class="bg-para bg3"></div>
<div class="sub-page">
    <a href="../../../assets/curriculum/Curriculum vitae ML 29gen21.pdf" target="_blank">
    <div class="container p-2 d-flex desktop flex-row ml-auto mr-auto">
        <div class="col-6 d-flex flex-column align-items-center justify-content-center p-3">
            <img id="foto3" class="img-fluid foto3 bianco-nero foto-profilo" height=""
                src="../../../assets/images/marco-longo.jpg">
        </div>
        <div class="col-6 staff-desc p-3 d-flex flex-column justify-content-center">
            <div>
                <h1>Marco Longo</h1>
                <h5><i class="font-verde">Avvocato</i></h5>
            </div>
            <!-- <p class="mt-5 paragrafo">
                Nato a Torino il giorno 2 luglio 1980.

                Dopo il diploma di maturità conseguito presso il Liceo Scientifico Carlo Cattaneo di Torino nel 1999,
                si laurea in Giurisprudenza nel 2004 presso l’Università degli Studi di Torino con una tesi in Diritto
                Internazionale conseguendo la votazione di 110/110 con
                lode e menzione.




            </p> -->
            
                <span class="leggi">Curriculum<svg enable-background="new 0 0 512 512" height="512" class="icon-leggi"
                        viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                            xmlns="http://www.w3.org/2000/svg" />
                    </svg>
                </span>
            
        </div>
    </div>
</a>
    <div class="container p-2 d-flex mobile riga-r ml-auto mr-auto">
        <a href="../../../assets/curriculum/Curriculum vitae ML 29gen21.pdf" target="_blank">

            <div class=" d-flex flex-column align-items-center justify-content-center p-3">
                <img id="foto33" class="img-fluid foto3 bianco-nero foto-profilo" height=""
                    src="../../../assets/images/marco-longo.jpg">
            </div>
            <div class="text-center staff-desc p-3 d-flex flex-column justify-content-center">
                <div>
                    <h1>Marco Longo</h1>
                    <h5><i class="font-verde">Avvocato</i></h5>
                </div>
                <!-- <p class="mt-5 paragrafo">
                Nato a Torino il giorno 2 luglio 1980.

                Dopo il diploma di maturità conseguito presso il Liceo Scientifico Carlo Cattaneo di Torino nel 1999,
                si laurea in Giurisprudenza nel 2004 presso l’Università degli Studi di Torino con una tesi in Diritto
                Internazionale conseguendo la votazione di 110/110 con
                lode e menzione.




            </p> -->

                <!-- <span class="leggi asc">Curriculum<svg enable-background="new 0 0 512 512"
                    height="512" class="icon-leggi" viewBox="0 0 512 512" width="512"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                        xmlns="http://www.w3.org/2000/svg" />
                </svg>
            </span> -->
            </div>
        </a>
    </div>
</div>
<div style="background-image: url('../../../assets/images/francesca-peyron.jpg');" class="bg-para bg4"></div>

<div class="sub-page">
    <a href="../../../assets/curriculum/Curriculum Vitae avv. Francesca Peyron.pdf" target="_blank">
    <div class="container p-2 d-flex desktop flex-row ml-auto mr-auto">
        <div class="col-6 staff-desc text-right p-3 d-flex flex-column justify-content-center">
            <!-- <div class="text-right"> -->
            <div>
                <h1>Francesca Peyron</h1>
                <h5><i class="font-verde">Avvocato</i></h5>
            </div>
            <!-- </div> -->
            <!-- <p class="mt-5 paragrafo">
                Nata a Torino il 2 novembre 1971.

                Dopo aver conseguito il diploma di maturità classica, il 15 luglio 1995 si è laureata in giurisprudenza
                presso l’Università degli Studi di Torino, con una tesi di laurea dal titolo: «La concussione per
                induzione» (relatore: prof. Carlo Federico Grosso – votazione 110/110 con lode e menzione).

            </p> -->
            
                <span class="leggi justify-content-end">Curriculum<svg enable-background="new 0 0 512 512" height="512"
                        class="icon-leggi" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                            xmlns="http://www.w3.org/2000/svg" />
                    </svg>
                </span>
            
        </div>
        <div class="col-6 d-flex flex-column align-items-center justify-content-center p-3">
            <img id="foto4" class="foto4 img-fluid bianco-nero foto-profilo" height=""
                src="../../../assets/images/peyron-profilo.jpg">
        </div>
    </div>

</a>
    <div class="container p-2 mobile d-flex flex-row ml-auto mr-auto">
        <a href="../../../assets/curriculum/Curriculum Vitae avv. Francesca Peyron.pdf" target="_blank">
            <div class="d-flex flex-column align-items-center justify-content-center p-3">
                <img id="foto44" class="foto4 img-fluid bianco-nero foto-profilo" height=""
                    src="../../../assets/images/peyron-profilo.jpg">
            </div>
            <div class="text-center staff-desc text-right p-3 d-flex flex-column justify-content-center">
                <!-- <div class="text-right"> -->
                <div>
                    <h1>Francesca Peyron</h1>
                    <h5><i class="font-verde">Avvocato</i></h5>
                </div>
                <!-- </div> -->
                <!-- <p class="mt-5 paragrafo">
                Nata a Torino il 2 novembre 1971.

                Dopo aver conseguito il diploma di maturità classica, il 15 luglio 1995 si è laureata in giurisprudenza
                presso l’Università degli Studi di Torino, con una tesi di laurea dal titolo: «La concussione per
                induzione» (relatore: prof. Carlo Federico Grosso – votazione 110/110 con lode e menzione).

            </p> -->
                <!-- <span routerLink="/curriculum/peyron" class="leggi asc justify-content-end">Curriculum<svg
                    enable-background="new 0 0 512 512" height="512" class="icon-leggi" viewBox="0 0 512 512"
                    width="512" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                        xmlns="http://www.w3.org/2000/svg" />
                </svg>
            </span> -->
            </div>
        </a>
    </div>

</div>
<div style="background-image: url('../../../assets/images/cassettoni.jpg');" class="bg-para bg4"></div>

<div class="ml-auto mr-auto container text-center swiper-container collaboratori-wrapper">

    <h1>Collaboratori</h1>
    <div class="carosello d-flex flex-wrap justify-content-center">
        <div class="figurina desktop m-3">
            <div class="immagine" style="background-image:url('../../../assets/images/edoardo-leone.jpg')"></div>
            <div class="testo">
                <h4>Edoardo Carlo Leoni</h4>
                <h6 class="font-verde"><i>Avvocato</i></h6>
                <a href="../../../assets/curriculum/2021.1.25_Curriculum vitae sito avv. Leoni.pdf" target="_blank">
                    <span class="leggi justify-content-end">Curriculum<svg enable-background="new 0 0 512 512"
                            height="512" class="icon-leggi" viewBox="0 0 512 512" width="512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                                xmlns="http://www.w3.org/2000/svg" />
                        </svg>
                    </span>
                </a>
            </div>
        </div>
        <div class="figurina mobile m-3">
            <a href="../../../assets/curriculum/2021.1.25_Curriculum vitae sito avv. Leoni.pdf" target="_blank">
            <div class="immagine" style="background-image:url('../../../assets/images/edoardo-leone.jpg')"></div>
            <div class="testo mt-2">
                <h4>Edoardo Carlo Leoni</h4>
                <h6 class="font-verde"><i>Avvocato</i></h6>
                
                    <span class="leggi justify-content-end">Curriculum<svg enable-background="new 0 0 512 512"
                            height="512" class="icon-leggi" viewBox="0 0 512 512" width="512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                                xmlns="http://www.w3.org/2000/svg" />
                        </svg>
                    </span>
                
            </div>
        </a>
        </div>
        <div class="figurina desktop m-3">
            <div class="immagine" style="background-image:url('../../../assets/images/laura-pucci.jpg')"></div>
            <div class="testo">
                <h4>Laura Pucci</h4>
                <h6 class="font-verde"><i>Avvocato</i></h6>
                <a href="../../../assets/curriculum/Curriculum Vitae avv. Laura Pucci.pdf" target="_blank">
                    <span class="leggi justify-content-end">Curriculum<svg enable-background="new 0 0 512 512"
                            height="512" class="icon-leggi" viewBox="0 0 512 512" width="512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                                xmlns="http://www.w3.org/2000/svg" />
                        </svg>
                    </span>
                </a>
            </div>
        </div>
        <div class="figurina mobile m-3">
            <a href="../../../assets/curriculum/Curriculum Vitae avv. Laura Pucci.pdf" target="_blank">
            <div class="immagine" style="background-image:url('../../../assets/images/laura-pucci.jpg')"></div>
            <div class="testo mt-2">
                <h4>Laura Pucci</h4>
                <h6 class="font-verde"><i>Avvocato</i></h6>
                
                    <span class="leggi justify-content-end">Curriculum<svg enable-background="new 0 0 512 512"
                            height="512" class="icon-leggi" viewBox="0 0 512 512" width="512"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="m506.134 241.843c-.006-.006-.011-.013-.018-.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284l70.164 69.824h-423.558c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104c.006-.006.011-.013.018-.019 7.833-7.818 7.808-20.522-.001-28.314z"
                                xmlns="http://www.w3.org/2000/svg" />
                        </svg>
                    </span>
                
            </div>
        </a>
        </div>
    </div>

    <!-- <div class="carosello swiper-wrapper">
        <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Edoardo Leone</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div>
        <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Laura Pucci</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div>
        <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Nome Cognome</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div>
        <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Nome Cognome</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div>
  <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Nome Cognome</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div>
        <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Nome Cognome</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div>
        <div class="figurina swiper-slide">
            <div class="immagine"></div>
            <div class="testo">
                <h4>Nome Cognome</h4>
                <h6><i>Avvocato</i></h6>
            </div>
        </div> 
        
    </div> -->
    <!-- <div class="d-flex flex-row mt-3 justify-content-center">
        <div class="navs mr-3 prev"><img src="../../../assets/images/next.svg" style="transform: rotate(180deg);" height="36px">
        </div>
        
        <div class="navs ml-3 next">
            <img src="../../../assets/images/next.svg" height="36px"></div>
    </div> -->
</div>


<app-scrolltop></app-scrolltop>
<app-footer></app-footer>