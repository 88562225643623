import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-contatti',
  templateUrl: './contatti.component.html',
  styleUrls: ['./contatti.component.scss']
})
export class ContattiComponent implements OnInit {

  constructor(private http: HttpClient, public dialog: MatDialog) { }
  formContatti = new FormGroup({
    nome: new FormControl('', Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    messaggio: new FormControl('', Validators.required),
    termini: new FormControl(null, [Validators.required])
  })
  sending = false;
  ngOnInit(): void {
    var vw = window.outerWidth;
    if (vw <= 1024 && vw > 320) {
      window.onscroll = function (e) {

        if (window.scrollY > 70) {
          document.getElementById("tornasu").style.opacity = "1"
        } else {
          document.getElementById("tornasu").style.opacity = "0"

        }

      };
    }
  }


  noerror(el) {
    if (el == 'email') {
      document.getElementById(el).classList.remove('error')
    } else if (el == 'nome') {
      document.getElementById(el).classList.remove('error')
    } else if (el == 'msg') {
      document.getElementById(el).classList.remove('error')
    } else if (el == 'terminil') {
      document.getElementById(el).classList.remove('errorc')
    }
  }

  onSubmit() {
    console.log(this.formContatti.controls.termini.value)

    if (this.formContatti.valid && this.formContatti.controls.email.value != '' && this.formContatti.controls.termini.value) {
      // console.log(this.formContatti)

      this.sending = true;







      //   let emailObj = {
      //     message: this.messageForm.controls.messaggio.value,
      //     from: this.messageForm.controls.email.value,
      //     oggetto: 'Messaggio dal sito.',
      //     telefono: this.messageForm.controls.telefono.value,
      //     categoria: this.route.split("/", 2)[1].toUpperCase,
      //     path: this.filePath
      //   }



      //   setTimeout(()=>{
      //     console.log('send');
      //   this.http.post<any>(environment.url + "/send", emailObj, optionse).subscribe(
      //     (data) => {
      //       // window.location.reload()
      //     },
      //     (err) => {
      //       console.log(err);
      //     }
      //   );
      // },3000);



      let messaggio = {
        nome: this.formContatti.controls.nome.value,
        email: this.formContatti.controls.email.value,
        messaggio: this.formContatti.controls.messaggio.value,
      }
      console.log(messaggio)
      this.http.post("https://imperatoassociati.it/mail", messaggio, { responseType: 'text' }).subscribe(
        (data) => {
          // console.log(data)
          // window.location.reload()
          this.sending = false;
          let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            // width: '450px',
            data: { titolo: "Messaggio inviato!", testo: "Riceverai una risposta a breve!" }

          });

          dialogRef.afterClosed().subscribe(result => {

            window.location.reload();

          });

        },
        (err) => {
          console.log(err);
          this.sending = false;

          this.dialog.open(ConfirmDialogComponent, {
            // width: '450px',
            data: { titolo: "OOOPS!", testo: "Qualcosa è andato storto, riprova più tardi!" }
          });
        }
      );




    } else {
      let email = this.formContatti.controls.email.valid;
      let emailv = this.formContatti.controls.email.value;
      let nome = this.formContatti.controls.nome.valid;
      let msg = this.formContatti.controls.messaggio.valid;
      let msgv = this.formContatti.controls.messaggio.value;

      console.log(emailv + 'e')
      console.log(this.formContatti.controls.nome.value + 'n')
      console.log(msgv + 'e')
      // if(!emailv){
      //           document.getElementById('email').classList.add('error')
      // }
      //errori sui campi del form
      // if(emailv=='' || (emailv =='' && !msgv)){
      //   document.getElementById('email').classList.add('error')
      // }
      // if((!email && !msg) || (emailv == null && msgv  == null) || (msgv == '' && emailv == '') || (msgv == null && emailv == '') || (msgv == '' && emailv == null)){
      //   document.getElementById('email').classList.add('error')
      //   document.getElementById('msg').classList.add('error')
      // }else if ((!email && msg) || (emailv == null && msg) || (emailv == '' && msg) || (emailv == '' && msgv!=null)|| (emailv == '' && msgv!='')){
      //   document.getElementById('email').classList.add('error')
      // }else if ((!msg && email) || (msgv == null && email) || (msgv == '' && email)){
      //   document.getElementById('msg').classList.add('error')
      // }
      console.log(emailv + 'ccc')
      if (emailv == '' || !email) {
        document.getElementById('email').classList.add('error')
      }
      if (msgv == '' || !msg) {
        document.getElementById('msg').classList.add('error')
      }
      if (this.formContatti.controls.nome.value == '' || !nome) {
        document.getElementById('nome').classList.add('error')
      }
      if (this.formContatti.controls.termini.value == null || !this.formContatti.controls.termini.value) {
        document.getElementById('terminil').classList.add('errorc')
      }
    }



  }//submit endformContatti


  // }else{

  //   return console.log("novalid");
  // }

  // } c




}
