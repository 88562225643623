<app-menubar></app-menubar>

<div style="height: auto; padding-bottom: 4rem;" class="header-page margin-menu">
    <div class="container chisiamo ml-auto pl-0 pr-0 pt-5 mt-3 mr-auto">
        <h1>Informativa relativa al trattamento dei dati personali</h1>

        <p class="mt-5 pt-3 paragrafo">


            Ai sensi dell’art. 13 del Regolamento UE n. 2016/679 (di seguito “GDPR 2016/679”), recante disposizioni a
            tutela delle persone e di altri soggetti rispetto al trattamento dei dati personali, desideriamo informarLa
            che i dati personali da Lei forniti formeranno oggetto di trattamento nel rispetto della normativa sopra
            richiamata e
            degli obblighi di riservatezza cui è tenuto il professionista.<br><br>
            <b>1) Titolare del trattamento, Responsabile del trattamento della Protezione dei Dati Personali e
                incaricati</b><br>
            Il Titolare del trattamento è lo Studio IMPERATO E ASSOCIATI, con sede in Torino corso Re Umberto, 77, nella
            persona del Legale Rappresentante <i>pro tempore.</i>
            Il responsabile del trattamento è l’avv. Lorenzo Imperato, nato a Torino il 12 settembre 1966, ivi residente
            in
            via G. Giolitti, 45, c.f. MPRLNZ66P12L219J, socio e legale rappresentante dello studio.<br><br>
            Il responsabile della protezione dei dati (DPO) è l’avv. Francesca Peyron, che potrà essere contattato per
            ogni
            chiarimento all’indirizzo email <a href="mailto:francesca.peyron@imperatoassociati.it"
                class="font-verde">francesca.peyron@imperatoassociati.it.</a><br><br>
            L’elenco aggiornato di tutti i soggetti incaricati al trattamento dei dati, in qualità di collaboratori
            dello Studio IMPERATO E ASSOCIATI nell’esercizio dell’attività professionale, ai sensi dell’art. 28 del
            GDPR, è disponibile presso lo Studio IMPERATO E ASSOCIATI ed è conoscibile attraverso apposita richiesta
            formulata con le
            modalità di seguito indicate.<br><br>
            <b>2) Finalità del trattamento</b><br>
            I dati personali da Lei forniti sono necessari per lo svolgimento del rapporto lavorativo e per l’esercizio
            del
            diritto di difesa.<br><br>
            <b>3) Modalità di trattamento e conservazione</b><br>
            Il trattamento sarà svolto in forma automatizzata e/o manuale, nel rispetto di quanto previsto dall’art. 32
            del GDPR 2016/679 in materia di misure di sicurezza, ad opera di soggetti appositamente incaricati e in
            ottemperanza a quanto previsto dall’art. 29 GDPR 2016/ 679.<br><br>
            Le segnaliamo che, nel rispetto dei principi di liceità, limitazione delle finalità e minimizzazione dei
            dati, ai sensi dell’art. 5 GDPR 2016/679, previo il Suo consenso libero ed esplicito espresso in calce alla
            presente informativa, i Suoi dati personali saranno conservati per il periodo di tempo necessario per il
            conseguimento delle finalità per le quali sono raccolti e trattati. Si segnala, inoltre, che a seguito della
            conclusione della pratica la relativa documentazione verrà conservata per un periodo complessivo di anni 10,
            al termine del quale tutti i documenti verranno distrutti secondo le modalità indicate nel registro
            trattamento dati consultabile
            presso lo studio IMPERATO E ASSOCIATI.<br><br>
            <b>4) Ambito di comunicazione e diffusione</b><br>
            Informiamo inoltre che i dati raccolti non saranno mai diffusi e non saranno oggetto di comunicazione senza
            Suo esplicito consenso, salvo le comunicazioni necessarie che possono comportare il trasferimento di dati ad
            enti pubblici, a consulenti o ad altri soggetti per l’adempimento degli obblighi di legge.<br><br>
            <b>5) Trasferimento dei dati personali</b><br>
            I suoi dati non saranno trasferiti né in Stati membri dell’Unione Europea né in Paesi terzi non appartenenti
            all’Unione Europea.<br><br>
            <b>6) Categorie particolari di dati personali</b><br>
            Ai sensi degli articoli 9 e 10 del Regolamento UE n. 2016/679, Lei potrebbe conferire al professionista dati
            qualificabili come “categorie particolari di dati personali” e cioè quei dati che rivelano <i>“l'origine
                razziale o etnica, le opinioni politiche, le convinzioni religiose o filosofiche, o l'appartenenza
                sindacale, nonché dati genetici, dati biometrici intesi a identificare in modo univoco una persona
                fisica,
                dati relativi alla salute o alla vita sessuale o all’orientamento sessuale della persona”.</i> Tali
            categorie di
            dati potranno essere trattate solo
            previo Suo libero ed esplicito consenso, manifestato in forma scritta in calce alla presente
            informativa.<br><br>
            <b>7) Diritti dell’interessato</b><br>
            In ogni momento, Lei potrà esercitare, ai sensi degli articoli dal 15 al 22 del Regolamento UE n. 2016/679,
            il
            diritto di:<br>
            <b>a)</b> chiedere la conferma dell’esistenza o meno di propri dati personali;<br>
            <b>b)</b> ottenere le indicazioni circa le finalità del trattamento, le categorie dei dati personali, i
            destinatari
            o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati e, quando possibile, il
            periodo
            di conservazione;<br>
            <b>c)</b> ottenere la rettifica e la cancellazione dei dati;<br>
            <b>d)</b> ottenere la limitazione del trattamento;<br>
            <b>e)</b> ottenere la portabilità dei dati, ossia riceverli da un titolare del trattamento, in un formato
            strutturato, di uso comune e leggibile da dispositivo automatico, e trasmetterli ad un altro titolare del
            trattamento senza
            impedimenti;<br>
            <b>f)</b> opporsi ad un processo decisionale automatizzato relativo alle persone fisiche, compresa la
            profilazione.<br>
            <b>h)</b> chiedere al titolare del trattamento l’accesso ai dati personali e la rettifica o la cancellazione
            degli
            stessi o la limitazione del trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto
            alla portabilità
            dei dati;<br>
            <b>i)</b> revocare il consenso in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul
            consenso
            prestato prima della revoca;<br>
            <b>j)</b> proporre reclamo a un’autorità di controllo.<br><br>
            Qualsiasi richiesta scritta per l’esercizio dei suoi diritti, in relazione ai dati personali trattati dallo
            studio IMPERATO E ASSOCIATI, potrà essere inviata allo studio IMPERATO E ASSOCIATI, presso la sede legale in
            Torino, corso Re Umberto, 77, oppure all'indirizzo di posta elettronica ordinaria
            segreteria@imperatoassociati.it o direttamente al DPO nominato avv. Francesca Peyron all’indirizzo
            <a href="mailto:francesca.peyron@imperatoassociati.it"
                class="font-verde">francesca.peyron@imperatoassociati.it.</a>.<br><br>
            La presente informativa sarà soggetta ad aggiornamenti. Lo Studio IMPERATO E ASSOCIATI invita, pertanto, gli
            Assistiti che intendano conoscere le modalità di trattamento dei Dati Personali raccolti, a consultare
            periodicamente sul sito internet dello studio all’indirizzo web <a href="https://www.imperatoassociati.it"
                class="font-verde">www.imperatoassociati.it.</a>, l’informativa
            aggiornata.

            <br><br><br>
            <a style="color: #89a02c;"
                href="../../../assets/vario/IEA Informativa sul trattamento dei dati personali 25feb2021.pdf"
                target="_blank">Scarica il modulo relativo alla privacy ed al trattamento dei dati personali.</a>
        </p>
    </div>


</div>
<div style="background-image: url('../../../assets/images/cassettoni.jpg');transform: translateY(3rem);" id="bg1"
    class="bg1 bg-para">

</div>




<app-scrolltop></app-scrolltop>
<app-footer></app-footer>