<app-menubar></app-menubar>

<div class="header-page margin-menu">
    <div class="container chisiamo ml-auto pl-0 pr-0 pt-5 mt-3 mr-auto">
        <h1>Contatti</h1>
        <!-- <p class="mt-5 pt-3 paragrafo">
            Lo studio <span class="font-verde">Imperato e Associati</span> è composto da quattro professionisti: <span
                class="font-verde">Lorenzo Imperato</span>, <span class="font-verde">Raffaella Enrietti</span>, <span
                class="font-verde">Marco Longo</span> e <span class="font-verde">Francesca Peyron</span>.
            <br>
            Insieme hanno lavorato per <span class="font-verde">oltre dieci anni</span> e, dal dicembre <span
                class="font-verde">2014</span>, hanno deciso di continuare a farlo in
            forma associata per valorizzare la loro forte sintonia e la spiccata capacità di <span
                class="font-verde">integrare le rispettive
                competenze</span>.
            <br>
            La peculiare esperienza professionale di ciascuno degli avvocati che compongono lo studio consente
            un’<span class="font-verde">assistenza attenta</span>, <span class="font-verde">qualificata</span> e <span
                class="font-verde">completa</span> sia al privato che all’impresa ed in molti settori del
            diritto, con particolare riferimento al diritto penale, al diritto tributario, alla responsabilità
            amministrativa degli enti e all’ordinamento di prevenzione.
        </p> -->
        <p class="paragrafo mt-5 mb-5">Scrivici! Risponderemo nel più breve tempo possibile a tutte le tue domande!</p>   

        <div class="contatti-wrapper">
            <div class="form-container">
             <form [formGroup]="formContatti">
                 
                    <div class="campo-form">
                        <label for="nome">Nome e Cognome</label>
                        <input id="nome" (click)="noerror('nome')" type="text" formControlName="nome">
                    </div>
                    <div class="campo-form">
                     <label for="email">E-mail</label>
                     <input (click)="noerror('email')" id="email" type="email" required formControlName="email">                    </div>
                    <div class="campo-form">
                        <label for="msg">Messaggio</label>
                        <textarea id="msg" (click)="noerror('msg')" rows="6"  formControlName="messaggio"></textarea>
                    </div>
                    <div class="campo-form">
                     <div class="d-flex flex-row align-items-center">
                         <input (click)="noerror('terminil')" type="checkbox" id="termini" formControlName="termini" >
                     <label class="pp" routerLink="/privacy-policy" style="margin-left: 16px;" id="terminil" for="termini">Accetto il trattamento dei dati personali</label>
                     </div>
                    </div>
                    
                    <span class="bottone" (click)="onSubmit()">
                        Invia
                    </span>
                </form>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2818.4716645950575!2d7.666207216225782!3d45.05594226861123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47886d6d2e937ddf%3A0x759af6ac95c96314!2sstudio%20Imperato%20e%20associati!5e0!3m2!1sit!2sit!4v1613037312402!5m2!1sit!2sit" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>

    
    <app-footer></app-footer>
</div>



<app-scrolltop></app-scrolltop>
<app-loadermsg *ngIf="sending"></app-loadermsg>