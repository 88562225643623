import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {
  constructor(private route: ActivatedRoute) { }
  param;
  titolo;
  testo;
  curriculum;

  ngOnInit(): void {
    window.scrollTo(0,0)
    // console.log(this.route.snapshot.params)
    this.param = this.route.snapshot.params;

    if(this.param.nome == "imperato"){
      this.curriculum = "../../../assets/curriculum/curriculum-imperato.pdf";
    }else if(this.param.nome == "peyron"){
      this.curriculum = "../../../assets/curriculum/curriculum-peyron.pdf";
    }
      
    
  }

}
