<div>
    <h1 mat-dialog-title>{{data.titolo}}</h1>
<div  mat-dialog-content>
  <p>{{data.testo}}</p>
</div>
<!-- <div class="testo" [innerHTML]="testo"></div>
</div> -->
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
  <!-- <button mat-button [mat-dialog-close]="data.testo" cdkFocusInitial>Ok</button> -->
  <div class="chiudi" (click)="chiudi()">Chiudi</div>
</div>
</div>