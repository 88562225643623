<app-menubar></app-menubar>
<div class=" margin-menu">
    <div class="container ml-auto pl-0 pr-0 pt-5 mt-3 mr-auto">
        <h1>Lo Studio</h1>
        <div class="row riga1 pb-4 mt-5 pt-5">
            <p class="mt-5 pt-3 paragrafo col-lg-4 pb-5">
        
                Lo studio Imperato e Associati è composto da quattro professionisti: Lorenzo Imperato, Raffaella Enrietti,
                Marco Longo e Francesca Peyron.
    
                Insieme hanno lavorato per oltre dieci anni e, dal dicembre 2014, hanno deciso di continuare a farlo in
                forma associata per valorizzare la loro forte sintonia e la spiccata capacità di integrare le rispettive
                competenze.            
            </p>
            <img class="col-lg-8 img-fluid pb-5" src="../../../assets/images/STUDIO1.jpg">
        </div>

        <div id="riga2" class="mt-5 row animate__animated flex-nowrap studio pb-4 pt-4">
            <img class="col-lg-8 img-fluid pb-5" src="../../../assets/images/studio2.jpg">

            <p class="mt-5 pt-3 paragrafo col-lg-4 pb-5">
        
                La peculiare esperienza professionale di ciascuno degli avvocati che compongono lo studio consente
                un’assistenza attenta, qualificata e completa – sia al privato, sia all’impresa – in molti settori del
                diritto, con particolare riferimento al diritto penale, al diritto tributario, alla responsabilità
                amministrativa degli enti e all’ordinamento di prevenzione.      
            </p>
        </div>
    </div>

</div>

<app-footer></app-footer>