<nav [ngClass]="{'font-white': isHomepage, 'font-black':!isHomepage}"
    class="font-white container desktop ml-auto mr-auto">
    <ul>
        <li [ngClass]="{'menu-link-dark': !isHomepage}" routerLink="/"><img *ngIf="isHomepage"
                src="../../../assets/images/logo-bianco.png" height="40px">
            <img *ngIf="!isHomepage" src="../../../assets/images/logo.png" style="transform: rotate(90deg);"
                height="86px">
        </li>
        <li [ngClass]="{'menu-link-dark': !isHomepage}" routerLink="/chi-siamo" class="menu-link">CHI SIAMO</li>
        <!-- <li [ngClass]="{'menu-link-dark': !isHomepage}" routerLink="/studio" class="menu-link">LO STUDIO</li> -->
        <li [ngClass]="{'menu-link-dark': !isHomepage}" routerLink="/aree-competenza" class="menu-link">AREE DI
            COMPETENZA</li>
        <li [ngClass]="{'menu-link-dark': !isHomepage}" routerLink="/gdpr" class="menu-link">
            GDPR</li>
        <!-- <li [ngClass]="{'menu-link-dark': !isHomepage}" class="menu-link">GALLERIA</li> -->
        <li [ngClass]="{'menu-link-dark': !isHomepage}" routerLink="/contatti" class="menu-link">CONTATTI</li>
    </ul>
</nav>


<div class="mobile">
    <nav class="nav-mob">
        <div>
            <img src="../../../assets/images/logo.png" routerLink="/" style="transform: rotate(90deg);">
            <!-- <img src="../../../assets/images/logo.png"[ngClass]="{'logo-nero': ishomepage}"  routerLink="/" style="transform: rotate(90deg);" > -->
            <!-- <img src="../../../assets/images/logo-bianco.png"[ngClass]="{'white-logo': !isHomepage}"   style="position: absolute;margin-left: -69px;margin-top: 28px;height: 38px;" routerLink="/" > -->
        </div>

        <div [ngClass]="{'cross': status == true}" id="mhm" (click)="toggleMenu()" class="hm">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </nav>
    <div id="mm" class="menu-mobile">
        <div id="mmc" class="menu-m-content justify-content-around">
            <div>
                <span>Menu</span>

                <div class="voices-mob mt-2 mb-5">
                    <h4 routerLink="/chi-siamo" (click)="toggleMenu()">CHI SIAMO</h4>
                    <!-- <h4 routerLink="/studio" (click)="toggleMenu()">STUDIO</h4> -->
                    <h4 routerLink="/aree-competenza" (click)="toggleMenu()">AREE DI COMPETENZA</h4>
                    <h4 routerLink="/gdpr" (click)="toggleMenu()">GDPR</h4>
                    <h4 routerLink="/contatti" (click)="toggleMenu()">CONTATTI</h4>

                </div>
            </div>

            <!-- <span>Contatti</span> -->
            <div class="d-flex flex-row align-items-center justify-content-around">

            </div>
        </div>
    </div>
</div>