<footer>
    <div class="container colonna justify-content-start mr-auto mt-5 ml-auto pb-5 pt-5">
        <div class="riga riga-r start jcentro">
            <div class="colonna start logo-f justify-content-end">
                <img src="../../../assets/images/logo-bianco.png" class="desktop" width="200px">
                <img src="../../../assets/images/logo-bianco.png" class="mobile" width="160px">
            </div>
            <div class="colonna bordo-sinistra start justify-content-start">
                <a class="footer-link" target="_blank" href="https://goo.gl/maps/G8Ctp7AEKVh5S6Gv9">
                    <div class="footer-element riga centro">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"
                            class="">
                            <g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="m407.579 87.677c-31.073-53.624-86.265-86.385-147.64-87.637-2.62-.054-5.257-.054-7.878 0-61.374 1.252-116.566 34.013-147.64 87.637-31.762 54.812-32.631 120.652-2.325 176.123l126.963 232.387c.057.103.114.206.173.308 5.586 9.709 15.593 15.505 26.77 15.505 11.176 0 21.183-5.797 26.768-15.505.059-.102.116-.205.173-.308l126.963-232.387c30.304-55.471 29.435-121.311-2.327-176.123zm-151.579 144.323c-39.701 0-72-32.299-72-72s32.299-72 72-72 72 32.299 72 72-32.298 72-72 72z"
                                        fill="#ffffff" data-original="#000000" class="" />
                                </g>
                            </g>
                        </svg>
                        <span>Corso Re Umberto, 77<br>Torino</span>
                    </div>
                </a>
                <a class="footer-link" target="_blank" href="tel:011538328">
                    <div class="footer-element riga centro">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"
                            class="">
                            <g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path
                                            d="M256,0C114.624,0,0,114.624,0,256s114.624,256,256,256s256-114.624,256-256S397.376,0,256,0z M384,400    c-149.984,0-272-122.016-272-272c0-8.832,7.168-16,16-16h59.072c8.832,0,16,7.168,16,16c0,27.648,6.016,53.984,17.888,78.24    c1.888,3.84,2.144,8.224,0.736,12.256c-1.376,4.032-4.32,7.328-8.16,9.184l-23.872,11.52    c19.872,34.592,48.544,63.296,83.104,83.104l11.52-23.84c1.856-3.84,5.152-6.752,9.184-8.16c4.032-1.376,8.448-1.12,12.256,0.736    c24.32,11.872,50.624,17.888,78.24,17.888c8.832,0,16,7.168,16,16V384H400C400,392.832,392.832,400,384,400z"
                                            fill="#ffffff" data-original="#000000" class="" />
                                    </g>
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                                <g xmlns="http://www.w3.org/2000/svg">
                                </g>
                            </g>
                        </svg>
                        <span>Telefono<br>011 53 83 28</span>
                    </div>
                </a>
                <div class="footer-element footer-link riga centro">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0"
                        viewBox="0 0 611.999 611.999" style="enable-background:new 0 0 512 512" xml:space="preserve"
                        class="">
                        <g>
                            <g xmlns="http://www.w3.org/2000/svg">
                                <path id="Fax_1_"
                                    d="M587.02,125.52c-24.98,0-49.959,0-74.939,0c0-22.482,0-44.963,0-67.445c0-13.796-11.183-24.98-24.98-24.98   c-120.735,0-241.469,0-362.204,0c-13.796,0-24.98,11.183-24.98,24.98c0,22.482,0,44.963,0,67.445c-24.98,0-49.959,0-74.939,0   C11.183,125.52,0,136.704,0,150.5c0,81.6,0,163.2,0,244.8c0,13.796,11.183,24.98,24.98,24.98c24.98,0,49.959,0,74.939,0   c0,29.496,0,58.992,0,88.489c0,14.386,0,28.771,0,43.157c0,8.328,1.684,14.541,8.121,20.428c2.998,2.742,6.199,4.52,10.109,5.618   c3.621,1.015,7.2,0.929,10.86,0.929c58.997,0,117.994,0,176.99,0s117.994,0,176.99,0c3.662,0,7.244,0.086,10.867-0.93   c3.913-1.099,7.117-2.881,10.114-5.627c6.438-5.895,8.11-12.118,8.11-20.447c0-14.393,0-28.788,0-43.181   c0-29.478,0-58.957,0-88.435c24.98,0,49.959,0,74.939,0c13.796,0,24.98-11.183,24.98-24.98c0-81.6,0-163.2,0-244.8   C612,136.705,600.817,125.52,587.02,125.52z M62.449,213.573c-12.071,0-21.857-9.786-21.857-21.857s9.786-21.857,21.857-21.857   s21.857,9.786,21.857,21.857S74.52,213.573,62.449,213.573z M487.102,553.92H124.898V356.582h362.204V553.92z M487.102,125.52   c-120.735,0-241.469,0-362.204,0c0-14.155,0-28.311,0-42.465c0-13.796,11.183-24.98,24.98-24.98c104.081,0,208.164,0,312.245,0   c13.796,0,24.98,11.183,24.98,24.98C487.102,97.211,487.102,111.366,487.102,125.52z"
                                    fill="#ffffff" data-original="#000000" class="" />
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                        </g>
                    </svg>
                    <span>Fax<br>011 50 69 264</span>
                </div>
                <a class="footer-link" href="mailto:segreteria@imperatoassociati.it">
                    <div class="footer-element riga centro">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve"><g>
                            <g xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="M257,210c-24.814,0-45,20.186-45,45c0,24.814,20.186,45,45,45c24.814,0,45-20.186,45-45C302,230.186,281.814,210,257,210z    " fill="#ffffff" data-original="#000000"/>
                                </g>
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="M255,0C114.39,0,0,114.39,0,255s114.39,257,255,257s257-116.39,257-257S395.61,0,255,0z M362,330    c-20.273,0-38.152-10.161-49.017-25.596C299.23,319.971,279.354,330,257,330c-41.353,0-75-33.647-75-75c0-41.353,33.647-75,75-75    c16.948,0,32.426,5.865,45,15.383V195c0-8.291,6.709-15,15-15c8.291,0,15,6.709,15,15c0,33.36,0,41.625,0,75    c0,16.538,13.462,30,30,30c16.538,0,30-13.462,30-30c0-100.391-66.432-150-135-150c-74.443,0-135,60.557-135,135    s60.557,135,135,135c30,0,58.374-9.609,82.061-27.803c15.822-12.078,33.94,11.765,18.281,23.789    C328.353,408.237,293.665,420,257,420c-90.981,0-165-74.019-165-165S166.019,90,257,90c82.897,0,165,61.135,165,180    C422,303.091,395.091,330,362,330z" fill="#ffffff" data-original="#000000" />
                                </g>
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            <g xmlns="http://www.w3.org/2000/svg">
                            </g>
                            </g></svg>
                        <span>E-mail<br>segreteria@imperatoassociati.it</span>
                    </div>
                </a>
            </div>
        </div>
        <!-- <div class="riga mt-5 pt-1">
            <div class="colonna">
                <a class="footer-link" target="_blank" href="https://goo.gl/maps/G8Ctp7AEKVh5S6Gv9">
                    <div class="footer-element riga centro">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g xmlns="http://www.w3.org/2000/svg"><path d="m407.579 87.677c-31.073-53.624-86.265-86.385-147.64-87.637-2.62-.054-5.257-.054-7.878 0-61.374 1.252-116.566 34.013-147.64 87.637-31.762 54.812-32.631 120.652-2.325 176.123l126.963 232.387c.057.103.114.206.173.308 5.586 9.709 15.593 15.505 26.77 15.505 11.176 0 21.183-5.797 26.768-15.505.059-.102.116-.205.173-.308l126.963-232.387c30.304-55.471 29.435-121.311-2.327-176.123zm-151.579 144.323c-39.701 0-72-32.299-72-72s32.299-72 72-72 72 32.299 72 72-32.298 72-72 72z" fill="#ffffff" data-original="#000000" style="" class=""/></g></g></svg>
                        <span>Corso Re Umberto, 77<br>Torino</span>
                    </div>
                </a>
            </div>
        </div> -->
    </div>
    <div class="container pb-3 ml-auto mr-auto d-flex justify-content-center">
        <span style="cursor: pointer;" routerLink="/privacy-policy" class="font-grey font-12">Privacy Policy</span>
        <span style="margin: 0 8px;" class="font-grey font-12"> - </span>
        <a href="https://attimofotografico.com" style="text-decoration: none;" target="_blank"><span class="font-grey font-12">Attimo Foto Grafico © 2021</span></a>
    </div>
</footer>