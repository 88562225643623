import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { MenubarComponent } from './components/menubar/menubar.component';
import { BannerComponent } from './components/banner/banner.component';
import { ChiSiamoComponent } from './components/chi-siamo/chi-siamo.component';
import { FooterComponent } from './components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudioComponent } from './components/studio/studio.component';
import { AreeCompetenzaComponent } from './components/aree-competenza/aree-competenza.component';
import { CurriculumComponent } from './components/curriculum/curriculum.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScrolltopComponent } from './components/scrolltop/scrolltop.component';
import {MatDialogModule} from '@angular/material/dialog';
import { DialogAcComponent } from './components/dialog-ac/dialog-ac.component';
import { ContattiComponent } from './components/contatti/contatti.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { LoadermsgComponent } from './components/loadermsg/loadermsg.component';
import { PolicyComponent } from './components/policy/policy.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { GdprComponent } from './components/gdpr/gdpr.component';


@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    MenubarComponent,
    BannerComponent,
    ChiSiamoComponent,
    FooterComponent,
    StudioComponent,
    AreeCompetenzaComponent,
    CurriculumComponent,
    ScrolltopComponent,
    DialogAcComponent,
    ContattiComponent,
    ConfirmDialogComponent,
    LoadermsgComponent,
    PolicyComponent,
    NotfoundComponent,
    GdprComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatDialogModule,
    HttpClientModule,
    ReactiveFormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
