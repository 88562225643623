<app-menubar></app-menubar>
<div class="c-bg">
    <div class="wrapper-curr">
        <h1></h1>
        <div class="curr">
            <pdf-viewer [src]="curriculum" 
            [render-text]="true"
            style="display: block;"
></pdf-viewer>
        </div>
        <!-- <p>{{testo}}</p> -->
    </div>
</div>
<app-footer></app-footer>