import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadermsg',
  templateUrl: './loadermsg.component.html',
  styleUrls: ['./loadermsg.component.scss']
})
export class LoadermsgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
