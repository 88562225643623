import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scrolltop',
  templateUrl: './scrolltop.component.html',
  styleUrls: ['./scrolltop.component.scss']
})
export class ScrolltopComponent implements OnInit {

  constructor() { }
tornaSu(){
  window.scrollTo(0,0)
}
  ngOnInit(): void {

  }

}
