import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
url;
isHomepage:boolean;
status:boolean;
  constructor(private router: Router) { }
   
  toggleMenu(){
    if(!this.status){
      document.getElementById("mm").style.transform="scaleX(1)";
      document.getElementById("mmc").style.opacity="1"; 
      
    }else{
      document.getElementById("mmc").style.opacity="0";
      document.getElementById("mm").style.transform="scaleX(0)";


    }
    this.status = !this.status;
    // console.log(this.status)  
  }
 

  ngOnInit(): void {
    this.url = this.router.url
    if(this.url=="/"|| this.url=="/curriculum/imperato" || this.url=="/curriculum/enrietti" || this.url=="/curriculum/peyron" || this.url=="/curriculum/longo"){
      this.isHomepage=true;
    }else{
      this.isHomepage=false;
    }
    
  }

}
