import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { DialogAcComponent } from '../dialog-ac/dialog-ac.component';
declare let $: any;
declare let Swiper: any;
declare let gsap: any;
declare let ScrollTrigger: any;
export interface DialogData {
  titolo: string;
  testo: string;
}
@Component({
  selector: 'app-aree-competenza',
  templateUrl: './aree-competenza.component.html',
  styleUrls: ['./aree-competenza.component.scss']
})
export class AreeCompetenzaComponent implements OnInit {

  constructor(public dialog: MatDialog, private route:Router) {

    
  }
  titolo: string;
  testo: string;
  openDialog(tipo): void {
    if(tipo == 'penale'){
      this.titolo = "Diritto Penale";
      this.testo = '<p class="paragrafo">L\’area di attività dei professionisti verte, in particolare, nell\’ambito dei reati economici,'+
      'dell\’impresa, tributari e fallimentari, ove le molteplici competenze permettono sia'+
      'l\’ausilio consulenziale all\’imprenditore ed all\’impresa nella fisiologia, sia l\’assistenza'+
      'nella gestione della patologia (intesa come illecito patito od oggetto di indagini'+
      'nell\’esercizio dell\’attività di impresa o nel suo interesse e ciò nei rapporti economici - tra'+
      'privati, verso lo Stato e verso lo Stato Erario -, nella tutela della proprietà intellettuale e'+
      'dalla criminalità informatica, fino all\’assistenza dell\’impresa e dell\’imprenditore nella'+
      'fase prefallimentare e fallimentare).'+
      'Ancora, l\’ambito di attività riguarda anche l\’area della pubblica amministrazione,'+
      'apprestando sia la tutela del cittadino e dell\’impresa nei rapporti con le devianze della'+
      'Pubblica Amministrazione, sia la tutela del funzionario pubblico in relazione ai complessi'+
      'ambiti legislativi e regolamentari che regolano l\’agire del suo ufficio; inoltre, la'+
      
      'particolare sensibilità ai riflessi interdisciplinari della materia, permette di offrire'+
      'un\’assistenza specifica, completa ed attenta ai risvolti disciplinari ed erariali dell\’illecito.'+
      'Particolari e specifiche competenze riguardano poi il settore dell\’igiene e sicurezza sul'+
      'lavoro che muovono dall\’analisi dei presidi di prevenzione e protezione, nonché di quelli'+
      'di formazione/informazione, per giungere alla difesa dall\’illecito colposo. Questo'+
      'permette una completa assistenza che può iniziare dal momento genetico di progettazione'+
      'del sistema interno di verifica e controllo (predisposizione di deleghe, definizione di'+
      'mansioni ed ambiti specifici, ecc. ecc.), a quello finale della gestione dell\’evento di danno'+
      'e dell\’adeguamento del sistema azienda all\’intercettamento di futuri analoghi rischi.'+
      'Quanto al settore della tutela della sicurezza alimentare, sono state maturate competenze'+
      'sia dal lato del consumatore rimasto vittima di condotte illecite e desideroso di ristoro del'+
      'danno patito, sia dal lato del produttore, potendo offrirsi un\’ampia esperienza nella'+
      'gestione della problematica specifica e nella verifica ed identificazione dei processi'+
      'aziendali devianti e nella loro correzione, nonché rispetto alla normativa di settore, che'+
      'presenta specificità e complessità di rilievo.'+
      'Anche il diritto penale dell’edilizia e ambiente richiede approfondimento e progettualità'+
      'nella difesa, imponendo un necessario rapporto con la normativa di settore e con i'+
      'provvedimenti amministrativi legati alla condotta illecita (ad esempio: ordini di bonifica,'+
      'ordini di sospensione dei lavori e di demolizione) che, necessariamente, vengono adottati'+
      'nei casi concreti. I professionisti dello studio hanno maturato esperienza e professionalità'+
      'nel settore e, pertanto, sono in grado di guidare l\’assistito nelle articolate e determinanti'+
      'scelte che la materia impone.'+
      'Infine, i professionisti dello studio hanno maturato un\’approfondita esperienza in materia'+
      'di diritto penale di famiglia e minorile e stalking, potendo offrire la tutela sia della'+
      'persona offesa sia della persona indagata/imputata, con riferimento alle molteplici'+
      'fattispecie delittuose contro la famiglia, sia la tutela del minore, autore e vittima del reato,'+
      'quale soggetto autonomo di diritti soggettivi nell\’ambito del peculiare processo minorile;'+
      'inoltre, la particolare sensibilità ai riflessi interdisciplinari della materia, permette di'+
      'offrire un\’assistenza specifica, completa ed attenta anche con riferimento ai risvolti'+
      'stragiudiziali relativi alla risoluzione dei conflitti familiari, nonché all\’individuazione dei'+
      'più efficaci strumenti volti al recupero del minore. I professionisti dello studio hanno'+
      'altresì sviluppato una particolare competenza in relazione al reato di Stalking e, più in'+
      'generale ai reati di violenza contro le donne, ottenendo altresì l\’iscrizione al Fondo di'+
      'Solidarietà istituito dalla Regione Piemonte per la tutela legale delle donne vittime di'+
      'violenza.</p>';
    }else if(tipo=="tributario"){
      this.titolo = "Diritto Tributario";
      this.testo="Attività di verifica fiscale"+
      "<p class= \"paragrafo\">Lo studio è in grado di assistere il cliente – persona fisica o persona giuridica, residente e"+
      " non residente sul territorio dello Stato – sin dai primi rapporti con l’Amministrazione"+
      " Finanziaria o con la Guardia di Finanza, operando quali procuratori speciali nelle"+
      " operazioni di verifica, così fornendo l’indispensabile apporto professionale durante tutta"+
      " l’attività di verifica fiscale."+
      " L’assistenza comprende anche l’eventuale fase della definizione precontenziosa"+
      " nell’ambito delle procedure deflattive del contezioso (contraddittori ante accertamento,"+
      " accertamento con adesione, ravvedimento operoso)."+
      " I professionisti hanno altresì maturato una significativa esperienza quanto agli istituti"+
      " \“perdonistici\” (condoni fiscali, scudo fiscale, voluntary disclosure) e, pertanto, possono"+
      " offrire un’assistenza qualificata anche su questo piano di intervento."+
      " Contenzioso tributario"+
      "I professionisti dello studio si occupano dell’assistenza delle persone fisiche e delle"+
      "persone giuridiche dinnanzi agli organi della Giustizia tributaria in ogni grado del"+
      "giudizio sino al patrocinio presso la Corte di Cassazione."+
      "Lo studio segue altresì l’eventuale fase della conciliazione giudiziale sino alla definizione"+
      "della controversia nell’ambito del predetto istituto, curando i rapporti con"+
      "l’Amministrazione Finanziaria in ogni suo aspetto procedurale."+
      "Procedimento e processo per reati tributari"+
      "L’esperienza maturata in ambito penale tributario garantisce al Cliente una specifica"+
      "competenza nei rapporti con l’Autorità Giudiziaria rispetto alla notizia di reato, che possa"+
      "scaturire all’esito della verifica fiscale."+
      "I professionisti sono, pertanto, pienamente in grado di gestire sia il versante"+
      " amministrativo, sia il versante penale delle attività di verifica ed accertamento fiscale."+
      " Nel corso di questi anni, peraltro, i professionisti hanno intrattenuto e continuano ad"+
      " intrattenere rapporti professionali e di collaborazione con dottori commercialisti, esperti"+
      " contabili, consulenti d’impresa. Questi rapporti hanno consentito di sviluppare la capacità"+
      "di collaborare con profitto e soddisfazione con diverse professionalità, nei rispettivi"+
      "ambiti, anche per quanto le interferenza che necessariamente si manifestano fra l’ambito"+
      "fiscale e quello, parallelo, penale.</p>"
    }else if(tipo=="prevenzione"){
      this.titolo = "Ordinamento di prevenzione";
      
    }else if(tipo=="enti"){
      this.titolo = "Responsabilità amministrativa degli enti";
      
    }
    const dialogRef = this.dialog.open(DialogAcComponent, {
      // height: '300px',
      data: {titolo: this.titolo, testo: this.testo}
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    //   this.testo = result;
    // });
  }

  ngOnInit(): void {
    var vw = window.outerWidth;
    if(vw <= 1024 && vw >320){
      window.onscroll = function (e) {
    
        if(window.scrollY>70){
          document.getElementById("tornasu").style.opacity="1"
        }else{
          document.getElementById("tornasu").style.opacity="0"
    
        }
      
      };
    }
      
    
  }
}
    // var mySwiper = new Swiper('.swiper-container', {
    //   spaceBetween: 30,
    //   effect: 'slide',
    //   slidesPerView: 2.5,
    //   speed: 1000,
    //   centeredSlides: true,
    //   // autoplay: {
    //   //   delay: 3000,
    //   //   disableOnInteraction: false,
    //   // },
    //   // pagination: {
    //   //   el: '.swiper-pagination',
    //   //   clickable: true,
    //   // },
    //   navigation: {
    //     nextEl: '.next',
    //     prevEl: '.prev',
    //   },
    // });

  //   var tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".auno",
  //       start: "top bottom"
  //     }
  //     });

  //     tl.from('.auno img', {
  //       x:200,
  //       opacity:0,
  //       duration:1.5
  //     });
  //     tl.from(".auno .text", {
  //       opacity:0,
  //       duration:1
  //     }, "-=1")

  //     var tl2 = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: ".adue",
  //         start: "top bottom"
  //       }
  //       });
  
  //       tl2.from('.adue img', {
  //         x:200,
  //         opacity:0,
  //         duration:1.5
  //       });
  //       tl2.from(".adue .text", {
  //         opacity:0,
  //         duration:1
  //       }, "-=1")

  // }


