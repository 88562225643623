import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare let ScrollMagic: any;
declare let Swiper: any;
@Component({
  selector: 'app-chi-siamo',
  templateUrl: './chi-siamo.component.html',
  styleUrls: ['./chi-siamo.component.scss']
})
export class ChiSiamoComponent implements OnInit {
 sub;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    var mySwiper = new Swiper('.swiper-container', {
      slidesPerView:3.242,
      spaceBetween: 40,
      // effect: 'fade',
      speed: 1000,
      centeredSlides: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true,
      // },
      navigation: {
        nextEl: '.next',
        prevEl: '.prev',
      },
    });
    var vw = window.outerWidth;

    var bg11controller = new ScrollMagic.Controller();

    var foto1Controller = new ScrollMagic.Controller();
    var foto11Controller = new ScrollMagic.Controller();
    var foto22Controller = new ScrollMagic.Controller();
    var bg22Controller = new ScrollMagic.Controller();

if(vw <= 1024 && vw >320){
  window.onscroll = function (e) {

    if(window.scrollY>70){
      document.getElementById("tornasu").style.opacity="1"
    }else{
      document.getElementById("tornasu").style.opacity="0"

    }
  
  };
  new ScrollMagic.Scene({ triggerElement: "#foto11", triggerHook:0.6 })
  // .setClassToggle('#foto2', 'colore')
  // .setClassToggle('.foto4', 'colore')
  .setClassToggle('.bg1', 'bianco-nero')
  // .addIndicators() // add indicators (requires plugin)
  .addTo(bg11controller);
  new ScrollMagic.Scene({ triggerElement: "#foto11", triggerHook:0.7 })
  .setClassToggle('.foto1', 'colore')
  // .setClassToggle('#bg1', 'colore')
  // .addIndicators() 
  
  .addTo(foto11Controller);
  new ScrollMagic.Scene({ triggerElement: "#foto22", triggerHook:0.6 })
  // .setClassToggle('#foto2', 'colore')
  .setClassToggle('.foto2', 'colore')
  // .addIndicators() // add indicators (requires plugin)
  .addTo(foto22Controller);

  new ScrollMagic.Scene({ triggerElement: "#foto22", triggerHook:0.6 })
  // .setClassToggle('#foto2', 'colore')
  // .setClassToggle('.foto4', 'colore')
  .setClassToggle('.bg2', 'bianco-nero')
  // .addIndicators() // add indicators (requires plugin)
  .addTo(bg22Controller);
  new ScrollMagic.Scene({ triggerElement: "#foto33", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    .setClassToggle('.foto3', 'colore')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(foto22Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto33", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto4', 'colore')
    .setClassToggle('.bg3', 'bianco-nero')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(bg22Controller);
    new ScrollMagic.Scene({ triggerElement: "#foto44", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    .setClassToggle('.foto4', 'colore')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(foto22Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto44", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto4', 'colore')
    .setClassToggle('.bg4', 'bianco-nero')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(bg22Controller);
}else{
    // create a scene
    new ScrollMagic.Scene({ triggerElement: "#foto1", triggerHook:0.7 })
    .setClassToggle('.foto1', 'colore')
    // .setClassToggle('#bg1', 'colore')
    // .addIndicators() 
    
    .addTo(foto1Controller);
   

    var foto2Controller = new ScrollMagic.Controller();
    var foto4Controller = new ScrollMagic.Controller();
    var bg4controller = new ScrollMagic.Controller();
    // create a scene
    // new ScrollMagic.Scene({ triggerElement: "#foto2", triggerHook:0.7 })
    // // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto2', 'colore')
    // // .addIndicators() // add indicators (requires plugin)
    // .addTo(foto2Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto4", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    .setClassToggle('.foto4', 'colore')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(foto4Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto4", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto4', 'colore')
    .setClassToggle('.bg4', 'bianco-nero')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(bg4controller);

    new ScrollMagic.Scene({ triggerElement: "#foto3", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    .setClassToggle('.foto3', 'colore')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(foto4Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto3", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto4', 'colore')
    .setClassToggle('.bg3', 'bianco-nero')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(bg4controller);

    new ScrollMagic.Scene({ triggerElement: "#foto2", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    .setClassToggle('.foto2', 'colore')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(foto4Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto2", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto4', 'colore')
    .setClassToggle('.bg2', 'bianco-nero')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(bg4controller);

    new ScrollMagic.Scene({ triggerElement: "#foto1", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    .setClassToggle('.foto1', 'colore')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(foto4Controller);

    new ScrollMagic.Scene({ triggerElement: "#foto1", triggerHook:0.6 })
    // .setClassToggle('#foto2', 'colore')
    // .setClassToggle('.foto4', 'colore')
    .setClassToggle('.bg1', 'bianco-nero')
    // .addIndicators() // add indicators (requires plugin)
    .addTo(bg4controller);


   
  }

}

}
