import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AreeCompetenzaComponent } from './components/aree-competenza/aree-competenza.component';
import { ChiSiamoComponent } from './components/chi-siamo/chi-siamo.component';
import { ContattiComponent } from './components/contatti/contatti.component';
import { CurriculumComponent } from './components/curriculum/curriculum.component';
import { GdprComponent } from './components/gdpr/gdpr.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PolicyComponent } from './components/policy/policy.component';
import { StudioComponent } from './components/studio/studio.component';

const routes: Routes = [
  {
    path: "",
    component: HomepageComponent
  },
  {
    path: "chi-siamo",
    component: ChiSiamoComponent
  },
  {
    path: "studio",
    component: StudioComponent
  },
  {
    path: "aree-competenza",
    component: AreeCompetenzaComponent
  },
  {
    path: "curriculum/:nome",
    component: CurriculumComponent,
  },
  {
    path: "contatti",
    component: ContattiComponent,
  },
  {
    path: "privacy-policy",
    component: PolicyComponent,
  },
  {
    path: "gdpr",
    component: GdprComponent,
  },
  {
    path: "**",
    component: NotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
